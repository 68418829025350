<template>
  <div class="container">
    <div class="content">
      <img
        v-if="$route.query.program==='PIE'"
        style="display: block; margin: 0 auto; width: 100px; margin-top: 10%"
        src="../../assets/pieSelect.png"
      />
      <img
        v-if="$route.query.program==='POE'"
        style="display: block; margin: 0 auto; width: 100px; margin-top: 10%"
        src="../../assets/poeSelect.png"
      />
      <img
        v-if="$route.query.program==='MR'"
        style="display: block; margin: 0 auto; width: 100px; margin-top: 10%"
        src="../../assets/centerSelect.png"
      />
      <div class="title" style="color: #f65d4d">Confirm joining</div>
      <div class="title1">{{ name }}</div>
      <div class="title1">ID: {{ code }}<span style="margin-left:30px">Project Code: {{project_code}}</span></div>
      <el-input
        v-model="role"
        placeholder="Your Expected Role"
        style="width: 70%; margin: 30px auto; display: block"
      ></el-input>
      <div class="inputContainer">
        <el-input
          v-model="pwd"
          placeholder="Please enter the joining password/pincode for this entry"
        ></el-input>
        <div class="btn" @click="confirm">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addNewApplication } from '../../api/index'
import '../../assets/common/font.css'
import { getProject } from '../../utils/store'
export default {
  data () {
    return {
      entry_id: this.$route.query.entry_id,
      name: this.$route.query.name,
      code: this.$route.query.code,
      pwd: '',
      role: '',
      project_code: ''
    }
  },
  mounted () {
    if (this.$route.query.program === 'PIE') {
      this.project_code = '22-23PIE'
    }
    if (this.$route.query.program === 'POE') {
      this.project_code = '22-23POE'
    }
    if (this.$route.query.program === 'MR') {
      this.project_code = getProject()
    }
  },
  methods: {
    confirm () {
      addNewApplication(this.entry_id, this.pwd, this.role, this.$route.query.program).then((res) => {
        if (res.data.code === 0) {
          this.$message.success('Join successfully')
          this.$router.push({
            path: '/application',
            query: {
              id: res.data.data._id.$id
            }
          })
        } else if (res.data.code === -2) {
          this.$message.error(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/back.png');
  background-size: 100% 100%;
  background-position: 0 0;
  font-family: "DDINAlternateRegular";
  .content {
    width: 63%;
    margin: 0 auto;
    height: 100%;
    background: #fff;
    overflow: hidden;
    .title {
      width: 70%;
      font-size: 20px;
      font-weight: bold;
      margin: 24px auto;
    }
    .title1 {
      width: 70%;
      font-size: 18px;
      font-weight: bold;
      margin: 24px auto;
    }
    ::v-deep .el-input__inner {
      height: 46px;
      border: 2px solid #0e4890;
      border-radius: 8px;
    }
    .inputContainer {
      width: 70%;
      margin: 10px auto;
      display: flex;
      align-items: center;
      ::v-deep .el-input__inner {
        height: 46px;
        border: 2px solid #0e4890;
        border-radius: 8px 0px 0px 8px;
      }
      .btn {
        width: 186px;
        height: 42px;
        line-height: 40px;
        background: #0e4890;
        border-radius: 0px 8px 8px 0px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        border: 2px solid #0e4890;
        cursor: pointer;
      }
    }
  }
}
</style>
